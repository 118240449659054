import React, { useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	Typography,
	Checkbox,
	FormControlLabel,
	Fab,
	Grid,
	TextField,
	Container,
	Link,
	MobileStepper,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput,
	Paper,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CustomerFormData } from "components/CustomerFormV2/models/CustomerFormData";
import "./style.scss";
import {
	actions,
	fetchPincodeDetails,
	generateUserConsentOtp,
	resendUserConsentOtp,
	verifyUserConsentOtp,
	updateAccountType,
	triggerPabblyWebhook,
} from "../../store/customer/actions";
import {
	emailRegx,
	mobileRegx,
	panRegx,
	pincodeRegx,
	nameRegx,
	instituteNameRegx,
	positiveIntegerRegx,
} from "../../utils/commonRegExs";
import { useSearchParams } from "react-router-dom";
import { RootState } from "config/store";
import { checkForLoading, checkForDoneAction } from "utils/helpers";
import { ConnectedProps, connect } from "react-redux";
import { removeDoneActions } from "../../store/ui/actions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {
	EligibilityCodes,
	getEligibilityMessage,
} from "components/CustomerFormV2/helper";
import { monthlyIncomeOptions } from "utils/constants";
import { toUpper } from "lodash";
import VerifiedIcon from "@mui/icons-material/Verified";
import AccountTypeModal from "components/Modal/AccountTypeModal";
import { useTheme, styled } from "@mui/material/styles";
import { toast } from "react-toastify";

const mapStateToProps = (state: RootState) => 
{
	const { ui, customer } = state;
	return {
		pincodeLoader: checkForLoading(
			ui.spinners,
			actions.FETCH_PINCODE_DETAILS,
		),
		isPincodeDetailsFetched: checkForDoneAction(
			ui.doneActions,
			actions.FETCH_PINCODE_DETAILS,
		),
		pincodeDetails: customer.pincodeDetails,
		ui: ui,
		generateOTPLoader: checkForLoading(
			ui.spinners,
			actions.GENERATE_USER_CONSENT_OTP,
		),
		isOTPGenerated: checkForDoneAction(
			ui.doneActions,
			actions.GENERATE_USER_CONSENT_OTP,
		),
		generatedUserRequest: customer.generatedUserRequest,
		eligibilityResult: customer.eligibilityResult,
		resendOTPLoader: checkForLoading(
			ui.spinners,
			actions.RESEND_USER_CONSENT_OTP,
		),
		verifyOTPLoader: checkForLoading(
			ui.spinners,
			actions.VERIFY_USER_CONSENT_OTP,
		),
		isOTPVerified: checkForDoneAction(
			ui.doneActions,
			actions.VERIFY_USER_CONSENT_OTP,
		),
		isAccountTypeUpdated: checkForDoneAction(
			ui.doneActions,
			actions.UPDATE_ACCOUNT_TYPE,
		),
	};
};

const mapDispatchToProps = {
	fetchPincodeDetails,
	removeDoneActions,
	generateUserConsentOtp,
	resendUserConsentOtp,
	verifyUserConsentOtp,
	updateAccountType,
	triggerPabblyWebhook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

const Item = styled(Paper)(({ theme }) => ({
	// paddingInline: "20px",
	boxShadow: "none",
}));

const CustomerFormV2: React.FC<ReduxProps> = (props) => 
{
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useForm<CustomerFormData>({
		mode: "onChange",
	});

	const theme = useTheme();

	const [otpSent, setOtpSent] = useState(false);
	const [counter, setCounter] = useState(0);
	const [currentStepCompleted, setCurrentStepCompleted] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showFormStep2, setShowFormStep2] = useState(false);
	const [selectedTenure] = useState(12);
	const [openModal, setOpenModal] = useState(false);
	const [accntType, setAccntType] = useState("");
	const [searchParams] = useSearchParams();

	const {
		isPincodeDetailsFetched,
		pincodeLoader,
		fetchPincodeDetails,
		pincodeDetails,
		removeDoneActions,
		generateUserConsentOtp,
		resendUserConsentOtp,
		verifyUserConsentOtp,
		generateOTPLoader,
		isOTPGenerated,
		eligibilityResult,
		updateAccountType,
		isAccountTypeUpdated,
		triggerPabblyWebhook,
	} = props;

	const isReferral = searchParams.get("referral") === "true";
	const isSmartCredit = searchParams.get("smartcredit") === "true";
	const utmSource = searchParams.get("utm_source");

	const getLoanAmountTrackingDetails = () => ({
		value: watch("LoanAmount") || 50000,
		currency: "INR",
	});

	useEffect(() => 
	{
		const timer: any =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter]);

	useEffect(() => 
	{
		// Function to update windowWidth when the window is resized
		const handleResize = () => 
		{
			setWindowWidth(window.innerWidth);
		};

		// Attach the event listener when the component mounts
		window.addEventListener("resize", handleResize);

		// Remove the event listener when the component unmounts
		return () => 
		{
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => 
	{
		if (currentStepCompleted === 1) 
		{
			const timer = setTimeout(() => 
			{
				setShowFormStep2(true);
			}, 0.01);

			return () => 
			{
				clearTimeout(timer);
			};
		}
	}, [currentStepCompleted]);

	useEffect(() => 
	{
		if (props.isOTPVerified) 
		{
			//@ts-ignore
			fbq("track", "OTPVerified", getLoanAmountTrackingDetails());
			if (eligibilityResult) 
			{
				if (
					eligibilityResult.EligibilityCode ===
                    EligibilityCodes.PRE_APPROVED
				) 
				{
					watch("InstituteType") === "university" &&
                        //@ts-ignore
                        fbq("trackCustom", "PreApproved_Uni");
					//@ts-ignore
					fbq("track", "PreApproved", getLoanAmountTrackingDetails());
					//@ts-ignore
					fbq(
						"trackCustom",
						monthlyIncomeOptions.find(
							(e) => e.amount === watch("MonthlyIncome"),
						)?.trackingKey,
					);
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/09KlCPjE7IUYEKLQ3rso",
					});

					if (watch("LoanAmount") <= 100000) 
					{
						//@ts-ignore
						fbq("trackCustom", "PreApproved_0to1");
					}
					else if (
						watch("LoanAmount") > 100000 &&
                        watch("LoanAmount") <= 200000
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "PreApproved_1to2");
					}
					else if (
						watch("LoanAmount") > 200000 &&
                        watch("LoanAmount") <= 400000
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "PreApproved_2to4");
					}
					else if (
						watch("LoanAmount") > 400000 &&
                        watch("LoanAmount") <= 600000
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "PreApproved_2to4");
						//@ts-ignore
						fbq("trackCustom", "PreApproved_4to6");
					}
					else if (watch("LoanAmount") > 600000) 
					{
						//@ts-ignore
						fbq("trackCustom", "PreApproved_2to4");
						//@ts-ignore
						fbq("trackCustom", "PreApproved_4to6");
						//@ts-ignore
						fbq("trackCustom", "PreApproved_6Plus");
					}
					if (
						watch("LoanAmount") >= 500000 &&
                        watch("InstituteType") === "university"
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "Pre_CLG_Above4");
					}
					else if (
						watch("LoanAmount") >= 300000 &&
                        watch("InstituteType") === "university"
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "Pre_CLG_Above2");
					}
				}
				else if (
					eligibilityResult.EligibilityCode ===
                    EligibilityCodes.PRE_QUALIFIED
				) 
				{
					//@ts-ignore
					fbq("track", "PreQualified", getLoanAmountTrackingDetails());
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/zk5bCKjI7IUYEKLQ3rso",
					});
				}
				else 
				{
					//@ts-ignore
					fbq(
						"track",
						"ManualApproval",
						getLoanAmountTrackingDetails(),
					);
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/OuIoCKed7YUYEKLQ3rso",
					});
				}

				if (eligibilityResult.CreditLinePixelApproval) 
				{
					//@ts-ignore
					fbq("trackCustom", "CreditLineApprove");
					watch("InstituteType") === "university" &&
                        //@ts-ignore
                        fbq("trackCustom", "CreditLineApprove_Uni");

					if (
						Number(watch("LoanAmount")) >= 500000 &&
                        watch("InstituteType") === "university"
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "CLApp_CLG_Above4");
					}
					else if (
						Number(watch("LoanAmount")) >= 300000 &&
                        watch("InstituteType") === "university"
					) 
					{
						//@ts-ignore
						fbq("trackCustom", "CLApp_CLG_Above2");
					}
				}
			}
			removeDoneActions([actions.VERIFY_USER_CONSENT_OTP]);
		}
	}, [props.isOTPVerified]);

	useEffect(() => 
	{
		if (pincodeDetails && isPincodeDetailsFetched) 
		{
			setValue("State", pincodeDetails?.State);

			removeDoneActions([actions.FETCH_PINCODE_DETAILS]);
		}
	}, [pincodeDetails, isPincodeDetailsFetched]);

	useEffect(() => 
	{
		if (isOTPGenerated) 
		{
			setOtpSent(true);
			setCounter(30);
			setCurrentStepCompleted(2);
			//@ts-ignore
			fbq("track", "LeadAdd", getLoanAmountTrackingDetails());
			removeDoneActions([actions.GENERATE_USER_CONSENT_OTP]);
		}
	}, [isOTPGenerated]);

	useEffect(() => 
	{
		if (isAccountTypeUpdated) 
		{
			onVerifyOtp();
		}
		removeDoneActions([actions.UPDATE_ACCOUNT_TYPE]);
	}, [isAccountTypeUpdated]);

	const scrollIntoElement = (name: string) => 
	{
		document.getElementById(name)?.scrollIntoView({
			behavior: "smooth",
			inline: "end",
			block: "start",
		});
	};

	const onSubmitError = (data: any) => 
	{
		const errors = Object.keys(data);
		if (errors.length > 0) 
		{
			scrollIntoElement(data[errors[0]]?.ref?.name);
		}
	};

	const onSaveUserDetails = (data: CustomerFormData) => 
	{
		//@ts-ignore

		gtag("event", "conversion", {
			send_to: "AW-10862700578/bGN_CKW_7IUYEKLQ3rso",
		});
		const payload = { ...data };
		if (payload.CustomInstituteType) 
		{
			payload.InstituteType = payload.CustomInstituteType;
			delete payload.CustomInstituteType;
		}
		generateUserConsentOtp({
			...payload,
			LoanTenure: selectedTenure.toString(),
			UTMParams: searchParams.toString(),
		});
	};

	const onFirstStep = (data: CustomerFormData) => 
	{
		const payload = { ...data, Step: 1 };
		const isPhonepe = utmSource === "phonepe";
		const meetsEligibilityCriteria = isPhonepe
			? payload.LoanAmount >= 300000 && payload.MonthlyIncome >= 25000
			: true;
		if (
			(payload.Name &&
                payload.Mobile &&
                payload.Email &&
                payload.InstituteName &&
                payload.InstituteType &&
                (!isPhonepe ||
                    (payload.LoanAmount && payload.MonthlyIncome))) ||
            payload.StudentName
		) 
		{
			if (isPhonepe && !meetsEligibilityCriteria) 
			{
				toast(
					"Uh oh! You do not meet our eligibility criteria at this time.",
					{
						type: "error",
						position: "top-right",
					},
				);
			}
			else 
			{
				setCurrentStepCompleted(1);
				triggerPabblyWebhook({
					Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY1MDYzNzA0MzA1MjZlNTUzMTUxMzAi_pc",
					Data: payload,
				});
			}
		}
	};

	const onVerifyOtp = () => 
	{
		const { generatedUserRequest } = props;

		if (generatedUserRequest) 
		{
			//@ts-ignore
			gtag("event", "conversion", {
				send_to: "AW-10862700578/DuuvCKmu7IUYEKLQ3rso",
			});
			verifyUserConsentOtp({
				TxnId: generatedUserRequest.OtpTxnId,
				RequestId: generatedUserRequest.RequestId,
				Otp: watch("OTP") || "",
			});
		}
	};

	const onOpenMoal = () => 
	{
		setOpenModal(true);
	};

	const onClickAgree = (accountType: string) => 
	{
		const { generatedUserRequest } = props;
		updateAccountType({
			RequestId: generatedUserRequest?.RequestId,
			AccountType: accountType,
		});
		setAccntType(accountType);
		setOpenModal(false);
	};

	const onResendOTP = () => 
	{
		const { generatedUserRequest } = props;
		if (generatedUserRequest) 
		{
			resendUserConsentOtp({
				OtpTransactionId: generatedUserRequest.OtpTxnId,
			});
		}
	};

	const onFetchPincodeDetails = (value: string) => 
	{
		if (!value) return;
		if (value.length !== 6) return;

		fetchPincodeDetails({
			q: value,
		});
	};

	const result = !!eligibilityResult;

	if (result) 
	{
		return (
			<div className="result__main">
				<div>
					<h2>
						{eligibilityResult.EligibilityCode <= 2 ? (
							<>
								{"Congratulations"}{" "}
								<VerifiedIcon
									style={{
										color: "darkcyan",
									}}
								/>
							</>
						) : (
							<>{"Thank You"}</>
						)}
					</h2>
				</div>
				<div>
					<p>
						{eligibilityResult.EligibilityCode <= 2 ? (
							<span>
                                You have been
								<strong>
									{getEligibilityMessage(
										eligibilityResult.EligibilityCode,
									)}
								</strong>{" "}
								{"Our Team will get in touch with you"}
							</span>
						) : (
							<span>{"Our Team will get back to you"}</span>
						)}
					</p>
				</div>
			</div>
		);
	}

	const conditionalFormFields: JSX.Element = (
		<>
			<Grid item xs={12} sm={3}>
				<Controller
					name="LoanAmount"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: positiveIntegerRegx,
							message: "Invalid Loan Amount",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={field.onChange}
								value={field.value}
								error={!!errors.LoanAmount}
								helperText={errors.LoanAmount?.message}
								className="text__field no-border"
								placeholder="Loan Amount required"
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "LoanAmount",
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Controller
					name="MonthlyIncome"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: positiveIntegerRegx,
							message: "Invalid Monthly Income",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={field.onChange}
								value={field.value}
								error={!!errors.MonthlyIncome}
								helperText={errors.MonthlyIncome?.message}
								className="text__field no-border"
								placeholder="Monthly Income"
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "MonthlyIncome",
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
		</>
	);

	const formStep1: JSX.Element = (
		<>
			<Grid item xs={12} sm={3}>
				<Controller
					name={"Name"}
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: nameRegx,
							message: "Invalid Name",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={field.onChange}
								value={field.value}
								error={!!errors.Name}
								helperText={errors.Name?.message}
								className="text__field no-border"
								placeholder="Applicant Name"
								fullWidth
								size="small"
								InputProps={{
									id: "Name",
								}}
								variant="standard"
							/>
						</Item>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Controller
					name="Mobile"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: mobileRegx,
							message: "Invalid mobile number",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={field.onChange}
								value={field.value}
								error={!!errors.Mobile}
								helperText={errors.Mobile?.message}
								className="text__field no-border"
								placeholder="Applicant Phone Number"
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "Mobile",
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Controller
					name="Email"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: emailRegx,
							message: "Invalid email",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={field.onChange}
								value={field.value}
								error={!!errors.Email}
								helperText={errors.Email?.message}
								className="text__field no-border"
								placeholder="Applicant Email ID"
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "Email",
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
			{/* {isReferral || isSmartCredit ? null : (
				<Grid item xs={12} sm={3}>
					<Controller
						name="StudentName"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Field is required",
							},
							pattern: {
								value: nameRegx,
								message: "Invalid Student Name",
							},
						}}
						render={({ field }) => (
							<Item>
								<TextField
									onChange={field.onChange}
									value={field.value}
									error={!!errors.StudentName}
									helperText={errors.StudentName?.message}
									className="text__field no-border"
									placeholder="Student Name"
									variant="standard"
									fullWidth
									size="small"
									InputProps={{
										id: "StudentName",
									}}
								/>
							</Item>
						)}
					/>
				</Grid>
			)} */}
			<Grid item xs={12} sm={3}>
				<Controller
					name="InstituteType"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
					}}
					defaultValue=""
					render={({ field }) => (
						<Item>
							<FormControl fullWidth>
								<InputLabel
									className="input-label"
									htmlFor="institute-type"
								>
                                    Institute Type
								</InputLabel>
								<Select
									onChange={field.onChange}
									value={field.value}
									error={!!errors.InstituteName}
									fullWidth
									className="style-field"
									size="small"
									label="Institute Type"
									inputProps={{
										id: "institute-type",
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												"& .MuiMenuItem-root": {
													fontSize: "12px",
													border: "none",
												},
											},
										},
									}}
									input={
										<OutlinedInput
											classes={{
												notchedOutline: "no-border",
											}}
										/>
									}
								>
									<MenuItem value="university">
                                        College or University
									</MenuItem>
									{utmSource !== "phonepe" && [
										<MenuItem key={"school"} value="school">
                                            School
										</MenuItem>,
										<MenuItem
											key={"coaching"}
											value="coaching"
										>
                                            Coaching
										</MenuItem>,
										<MenuItem
											key={"online_course"}
											value="online_course"
										>
                                            Online Course
										</MenuItem>,
									]}
								</Select>
							</FormControl>
						</Item>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Controller
					name={"InstituteName"}
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: instituteNameRegx,
							message: "Invalid Institute Name",
						},
					}}
					defaultValue={""}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={field.onChange}
								value={field.value}
								error={!!errors.InstituteName}
								helperText={errors.InstituteName?.message}
								className="text__field no-border"
								placeholder={
									isSmartCredit
										? "College Name"
										: "Student's School/College Name"
								}
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "InstituteName",
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
			{utmSource === "phonepe" && conditionalFormFields}
		</>
	);

	const formStep2: JSX.Element = (
		<>
			{/* <Grid item xs={12} sm={3}>
				<Controller
					name="JobType"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
					}}
					defaultValue=""
					render={({ field }) => (
						<Item>
							<FormControl fullWidth>
								<InputLabel
									className="input-label"
									htmlFor="job-type"
								>
                                    Job Type
								</InputLabel>
								<Select
									onChange={field.onChange}
									value={field.value}
									error={!!errors.InstituteName}
									fullWidth
									className="style-field"
									size="small"
									placeholder="Job Type"
									inputProps={{
										id: "job-type",
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												"& .MuiMenuItem-root": {
													fontSize: "12px",
													border: "none",
												},
											},
										},
									}}
									input={
										<OutlinedInput
											classes={{
												notchedOutline: "no-border",
											}}
										/>
									}
								>
									<MenuItem value="salaried">
                                        Salaried
									</MenuItem>
									<MenuItem value="self_employed">
                                        Self-Employed
									</MenuItem>
								</Select>
							</FormControl>
						</Item>
					)}
				/>
			</Grid> */}
			{utmSource !== "phonepe" && conditionalFormFields}
			<Grid item xs={12} sm={3}>
				<Controller
					name="Pincode"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: pincodeRegx,
							message: "Invalid pincode",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={(e) => 
								{
									onFetchPincodeDetails(e.target.value);
									field.onChange(e.target.value);
								}}
								value={field.value}
								error={!!errors.Pincode}
								helperText={errors.Pincode?.message}
								className="text__field no-border"
								placeholder="Pincode"
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "Pincode",
									endAdornment: pincodeLoader ? (
										<CircularProgress size="1.5rem" />
									) : null,
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Controller
					name={"DateOfBirth"}
					control={control}
					rules={{
						required: {
							value: true,
							message: "Date of birth required",
						},
					}}
					defaultValue={""}
					render={({ field }) => 
					{
						return (
							<Item>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DesktopDatePicker
										inputFormat="DD/MM/YYYY"
										className="no-border"
										disableHighlightToday
										value={moment(field.value).format(
											"DD/MM/YYYY",
										)}
										onChange={(date: any) => 
										{
											field.onChange(
												date?.format("DD/MM/YYYY"),
											);
										}}
										disableFuture
										views={["year", "month", "day"]}
										renderInput={(params) => (
											<TextField
												{...params}
												className="dob__text__field no-border"
												error={!!errors?.DateOfBirth}
												helperText={
													errors?.DateOfBirth?.message
												}
												label="Date of Birth"
												variant="standard"
												fullWidth
												InputProps={{
													id: "DateOfBirth",
												}}
												InputLabelProps={{
													style: {
														fontSize: "12px",
														color: "rgb(154, 152, 152)",
														marginLeft: "12px",
													},
												}}
												size="small"
											/>
										)}
									/>
								</LocalizationProvider>
							</Item>
						);
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Controller
					name="Pan"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Field is required",
						},
						pattern: {
							value: panRegx,
							message: "Invalid pan number!",
						},
					}}
					render={({ field }) => (
						<Item>
							<TextField
								onChange={(e) =>
									field.onChange(toUpper(e.target.value))
								}
								value={field.value}
								error={!!errors.Pan}
								helperText={errors.Pan?.message}
								className="text__field no-border"
								placeholder="Applicant PAN"
								variant="standard"
								fullWidth
								size="small"
								InputProps={{
									id: "Pan",
								}}
							/>
						</Item>
					)}
				/>
			</Grid>
		</>
	);

	const formConsent: JSX.Element = (
		<div className="consent__container">
			<Grid>
				{/* <Controller
					name={"UserConsentCheck"}
					control={control}
					rules={{
						required: {
							value: true,
							message: "Consent is required",
						},
					}}
					render={({ field }) => 
					{
						return (
							<FormControlLabel
								className="consent__check"
								control={
									<Checkbox
										onChange={field.onChange}
										value={field.value}
									/>
								}
								label={
									<span className="consent__label">
                                        I understand the loan is for
                                        education-related purpose only
									</span>
								}
							/>
						);
					}}
				/> */}
				<FormControlLabel
					className="consent__check"
					control={
						<Checkbox
							disabled
							checked
							inputProps={{
								"aria-label": "controlled",
							}}
						/>
					}
					label={
						<span className="consent__label">
                            I give consent to Propelld and/or its lenders to
                            access my Credit Information and confirm that I have
                            understood and agree to comply with the{" "}
							<a
								href="https://www.propelld.com/terms"
								target="_blank"
								style={{ color: "#5476ff" }}
								rel="noreferrer"
							>
                                Terms of Service.
							</a>
							{/* {", "}
							<a
								href="https://www.propelld.com/privacy"
								target="_blank"
								style={{ color: "#5476ff" }}
								rel="noreferrer"
							>
                                Privacy Policy
							</a>
							{" and "}
							<a
								href="https://www.propelld.com/credit-terms"
								target="_blank"
								style={{ color: "#5476ff" }}
								rel="noreferrer"
							>
                                Credit Information Terms and Conditions.
							</a> */}
						</span>
					}
				/>
				<Controller
					name={"UserWhatsappConsentCheck"}
					control={control}
					render={({ field }) => 
					{
						return (
							<FormControlLabel
								className="consent__check"
								control={
									<Checkbox
										onChange={field.onChange}
										value={field.value}
										defaultChecked
										disabled
									/>
								}
								label={
									<span className="consent__label">
                                        I authorize Propelld to contact me
                                        overriding my registration with the NDNC
                                        registry.
									</span>
								}
							/>
						);
					}}
				/>
			</Grid>
		</div>
	);

	return (
		<Container>
			<form
				onSubmit={handleSubmit(
					otpSent
						? isReferral || isSmartCredit
							? onVerifyOtp
							: accntType
								? onVerifyOtp
								: onOpenMoal
						: currentStepCompleted === 1
							? onSaveUserDetails
							: onFirstStep,
					onSubmitError,
				)}
				method="dialog"
				className="form__main"
				autoComplete={"off"}
			>
				{!otpSent && (
					<div className="form__content">
						<Grid
							container
							spacing={3}
							// spacing={{ xs: 2, md: 3 }}
							// columns={{ xs: 4, sm: 8, md: 12 }}
						>
							{windowWidth > 700 ? (
								<>
									{formStep1}
									{formStep2}
								</>
							) : currentStepCompleted === 1 ? (
								<>{showFormStep2 && formStep2}</>
							) : (
								<>{formStep1}</>
							)}

							{windowWidth > 700 && <>{formConsent}</>}
						</Grid>
					</div>
				)}

				{otpSent && (
					<div className="otp-input__main">
						<Controller
							name={"OTP"}
							control={control}
							rules={{
								required: {
									value: true,
									message: "OTP is required",
								},
							}}
							render={({ field }) => 
							{
								return (
									<>
										<div className="otp">
											<Item>
												<TextField
													className="text__field no-border"
													onChange={field.onChange}
													value={field.value}
													variant="standard"
													placeholder={"Mobile OTP"}
													error={!!errors.OTP}
													helperText={
														errors.OTP?.message
													}
													InputProps={{
														id: "Mobile_OTP",
													}}
												/>
											</Item>
											{counter > 0 ? (
												<p>
													{" "}
                                                    Resend otp in {counter} secs
												</p>
											) : (
												<a
													onClick={onResendOTP}
													href="#"
												>
                                                    Resend OTP
												</a>
											)}
										</div>
									</>
								);
							}}
						/>
						{windowWidth <= 700 && <>{formConsent}</>}
					</div>
				)}

				<div className="footer">
					{!otpSent && windowWidth > 700 && (
						<Grid item xs={12}>
							<Button
								onClick={handleSubmit(
									onSaveUserDetails,
									onSubmitError,
								)}
								variant="contained"
								color="primary"
								startIcon={
									generateOTPLoader ? (
										<CircularProgress
											style={{ color: "white" }}
											size="1rem"
										/>
									) : undefined
								}
								// style={
								// 	watch("UserConsentCheck")
								// 		? {
								// 			backgroundColor: "#5383f9",
								// 			color: "white",
								// 		}
								// 		: {}
								// }
								// disabled={!watch("UserConsentCheck")}
							>
                                Next
							</Button>
						</Grid>
					)}

					{!otpSent && windowWidth <= 700 && (
						<Grid item xs={12}>
							<Button
								className="next__btn"
								onClick={handleSubmit(
									currentStepCompleted === 1
										? onSaveUserDetails
										: onFirstStep,
									onSubmitError,
								)}
								variant="contained"
								color="primary"
								startIcon={
									generateOTPLoader ? (
										<CircularProgress
											style={{ color: "white" }}
											size="1rem"
										/>
									) : undefined
								}
							>
                                Next
							</Button>
						</Grid>
					)}

					{otpSent && (
						<>
							{windowWidth > 700 ? (
								<Button
									onClick={handleSubmit(
										isReferral || isSmartCredit
											? onVerifyOtp
											: accntType
												? onVerifyOtp
												: onOpenMoal,
										onSubmitError,
									)}
									variant="contained"
									color="primary"
									startIcon={
										props.verifyOTPLoader ? (
											<CircularProgress
												style={{ color: "white" }}
												size="1rem"
											/>
										) : undefined
									}
									style={{
										backgroundColor: "#5383f9",
										color: "white",
										width: "200px",
										marginTop: "40px",
									}}
								>
									{"submit"}
								</Button>
							) : (
								<Button
									onClick={handleSubmit(
										isReferral || isSmartCredit
											? onVerifyOtp
											: accntType
												? onVerifyOtp
												: onOpenMoal,
										onSubmitError,
									)}
									variant="contained"
									color="primary"
									startIcon={
										props.verifyOTPLoader ? (
											<CircularProgress
												style={{ color: "white" }}
												size="1rem"
											/>
										) : undefined
									}
									style={
										// watch("UserConsentCheck") &&
										otpSent
											? {
												backgroundColor: "#5383f9",
												color: "white",
											}
											: {}
									}
									disabled={
										// !watch("UserConsentCheck") &&
										otpSent
									}
								>
									{"submit"}
								</Button>
							)}
						</>
					)}

					{windowWidth <= 700 && (
						<MobileStepper
							className="mobile__stepper"
							variant="dots"
							steps={3}
							position="static"
							activeStep={currentStepCompleted}
							sx={{ maxWidth: 400, flexGrow: 1 }}
							backButton
							nextButton
							style={{
								background: "transparent",
							}}
						/>
					)}
				</div>
			</form>
			{isReferral || isSmartCredit ? null : (
				<AccountTypeModal
					open={openModal}
					onClickAgree={onClickAgree}
				/>
			)}
		</Container>
	);
};

export default connector(CustomerFormV2);
