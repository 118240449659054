export const checkForLoading = (data: any, actionType: string) => 
{
	return data[actionType];
};

export const checkForDoneAction = (data: any, actionType: string) => 
{
	return data[actionType];
};

export const checkForErrors = (data: any, actionType: string) => 
{
	return data[actionType];
};

export const formatWithCurrency = (
	value: number | string | undefined,
	prefix = "₹",
) => 
{
	return prefix + " " + (value || "0");
};
