// eslint-disable-next-line @typescript-eslint/no-var-requires
const pmt = require("formula-pmt");

export enum EligibilityCodes {
    PRE_APPROVED = 1,
    PRE_QUALIFIED = 2,
    NEEDS_INSPECTION = 3,
    NOT_APPROVED = 4,
}

export const getEligibilityMessage = (code: number) => 
{
	switch (code) 
	{
	case EligibilityCodes.PRE_APPROVED:
		return "Pre Approved!";
	case EligibilityCodes.PRE_QUALIFIED:
		return "Pre Qualified!";
	default:
		return "";
	}
};

export const calcEmi = (loanAmount: number, tenure: number) => 
{
	return (Math.round(pmt(0.02, tenure, loanAmount)) * -1).toLocaleString(
		"en-IN",
		{
			currency: "INR",
		},
	);
};
