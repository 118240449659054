import React from "react";

import Layout from "components/Layout";
import CustomerFormV2 from "components/CustomerFormV2";

const HomePage = () => 
{
	return (
		<Layout>
			<CustomerFormV2 />
		</Layout>
	);
};

export default HomePage;
