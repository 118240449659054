// ApiUtils.js
import HttpStatusCode from "../models/HttpStatusCodes";
import ApiErrorHelper from "../models/ApiErrorHelper";
import { ServiceResponse } from "../models/ServiceResponse";

export const checkStatus = (response: Response) => 
{
	if (
		[
			HttpStatusCode.OK,
			HttpStatusCode.NOT_MODIFIED,
			HttpStatusCode.INTERNAL_SERVER_ERROR,
			HttpStatusCode.BAD_REQUEST,
		].includes(response.status)
	) 
	{
		return response.json();
	}

	throw ApiErrorHelper.getErrorFromNetworkError(response);
};

export const checkResponseCode = (response: ServiceResponse) => 
{
	if (["SUCCESS", 0].includes(response.Code)) 
	{
		return response;
	}
	else 
	{
		throw ApiErrorHelper.getErrorFromServiceError(response);
	}
};
