import types from "./actions";
import { each } from "lodash";

export interface State {
    spinners: {
        [name: string]: boolean
    }
    errors: { [name: string]: object }
    doneActions: {
        [name: string]: boolean
    }
}

const initUi: State = {
	spinners: {},
	errors: {},
	doneActions: {},
};

export default function reducer(state = initUi, action: any) 
{
	switch (action.type) 
	{
	case types.SHOW_SPINNER: {
		const spinners = { ...state.spinners };
		const context = action.payload.context;
		spinners[context] = true;
		return { ...state, spinners: spinners };
	}

	case types.HIDE_SPINNER: {
		const spinners = { ...state.spinners };
		if (action.payload) 
		{
			if (typeof action.payload === "string") 
			{
				spinners[action.payload] = false;
			}
			if (action.payload instanceof Array) 
			{
				each(action.payload, (key: string) => 
				{
					spinners[key] = false;
				});
			}
		}
		return { ...state, spinners: spinners };
	}

	case types.SHOW_ERROR: {
		const errors: any = { ...state.errors };
		const context = action.payload.context;
		if (!errors[context]) 
		{
			errors[context] = [action.payload];
		}
		else 
		{
			errors[context] = [...errors[context], action.payload];
		}
		return { ...state, errors: errors };
	}

	case types.REMOVE_ERRORS: {
		let errors = { ...state.errors };
		if (!action.payload) 
		{
			errors = {};
		}
		if (action.payload && action.payload instanceof Array) 
		{
			each(action.payload, (key: string) => 
			{
				delete errors[key];
			});
		}
		return { ...state, errors: errors };
	}

	case types.ADD_DONE_ACTIONS: {
		const doneActions = { ...state.doneActions };
		if (action.payload) 
		{
			const context = action.payload;
			doneActions[context] = true;
		}

		return { ...state, doneActions: doneActions };
	}

	case types.REMOVE_DONE_ACTIONS: {
		let doneActions = { ...state.doneActions };
		if (!action.payload) 
		{
			doneActions = {};
		}
		if (action.payload && action.payload instanceof Array) 
		{
			each(action.payload, (key: string) => 
			{
				doneActions[key] = false;
			});
		}
		return { ...state, doneActions: doneActions };
	}

	default:
		return state;
	}
}
