export const listOfStates = [
	"Andhra Pradesh",
	"Assam",
	"Bihar",
	"Chhattisgarh",
	"Chandigarh",
	"Delhi",
	"Goa",
	"Gujarat",
	"Haryana",
	"Himachal Pradesh",
	"Jharkhand",
	"Karnataka",
	"Kerala",
	"Madhya Pradesh",
	"Maharashtra",
	"Odisha",
	"Puducherry",
	"Punjab",
	"Rajasthan",
	"Tamil Nadu",
	"Telengana",
	"Uttarakhand",
	"Uttar Pradesh",
	"West Bengal",
];

export const loanAmountOptions = [
	{
		amount: 50000,
		label: "0 to 1 Lac",
	},
	{
		amount: 150000,
		label: "1 to 2 Lacs",
	},
	{
		amount: 300000,
		label: "2 to 4 Lacs",
	},
	{
		amount: 500000,
		label: "4 to 6 Lacs",
	},
	{
		amount: 700000,
		label: "Above 6 Lacs",
	},
];

export const monthlyIncomeOptions = [
	{
		amount: 25000,
		label: "Below 25,000",
		trackingKey: "PreApproved_below25k",
	},
	{
		amount: 37500,
		label: "25,000 to 49,999",
		trackingKey: "PreApproved_25to49",
	},
	{
		amount: 75000,
		label: "50,000 to 99,999",
		trackingKey: "PreApproved_50to99",
	},
	{
		amount: 100000,
		label: "1 Lac and Above",
		trackingKey: "PreApproved_above1Lac",
	},
];

export const jobTypes = [
	{
		value: "salaried",
		label: "salaried",
	},
	{
		value: "self_employed",
		label: "self_employed",
	},
];

export const instituteTypes = [
	{
		value: "school",
		label: "school",
	},
	{
		value: "university",
		label: "university",
	},
	{
		value: "other",
		label: "other",
	},
];

export const instituteTypesForSmartCredit = [
	{
		value: "school",
		label: "School",
	},
	{
		value: "university",
		label: "College",
	},
	{
		value: "coaching",
		label: "Coaching",
	},
];

export const accountTypes = [
	{
		value: "university",
		label: "institute_account",
	},
	{
		value: "personal",
		label: "personal_account",
	},
];
