import { NetworkRequest } from "./models/NetworkRequest";

class NetworkRequestHelper 
{
	static GET(url: string, params?: object, header?: object): NetworkRequest 
	{
		return {
			url: url,
			method: "GET",
			params: params || {},
			body: {},
			headers: header || {},
		};
	}
	static POST(
		url: string,
		body: object,
		params?: object,
		header?: object,
	): NetworkRequest 
	{
		return {
			url: url,
			method: "POST",
			params: params || {},
			body: body,
			headers: header || {},
		};
	}
}

export default NetworkRequestHelper;
