import serviceDev from "./service.dev";
import serviceProduction from "./service.prod";
import serviceStaging from "./service.staging";
import serviceKarma from "./service.karma";
import dot from "../../env.json";

let config = serviceDev;

if (dot.ENV === "dev") 
{
	config = serviceDev;
}
else if (dot.ENV === "staging") 
{
	config = serviceStaging;
}
else if (dot.ENV === "karma") 
{
	config = serviceKarma;
}
else 
{
	config = serviceProduction;
}

export default config;
