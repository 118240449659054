import React, { useEffect } from "react";

type Props = {
    children: React.ReactNode
}

const Page = (props: Props) => 
{
	useEffect(() => 
	{
		window.scrollTo(0, 0);
	}, []);

	return <main>{props.children}</main>;
};

export default Page;
