import React, { useState } from "react";
import { Button, Box, Typography, Modal, IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";

const style = {
	position: "absolute",
	width: "90%", // Adjusted width for larger mobile screens
	maxWidth: 280, // Maximum width to maintain readability
	backgroundColor: "white",
	boxShadow: 24,
	p: 4,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: 5,
	display: "flex", // Center elements horizontally
	flexDirection: "column",
	alignItems: "center",
};

const closeButtonStyle = {
	position: "absolute",
	top: 10,
	right: 10,
};

interface Props {
    open: boolean
    onClickAgree: any
}

const AccountTypeModal: React.FC<Props> = (props) => 
{
	// const [open, setOpen] = useState(true);

	const { open, onClickAgree } = props;

	return (
		<div>
			<Modal
				open={open}
				onClose={() => onClickAgree("personal")}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style} className="modal__container">
					<IconButton
						sx={closeButtonStyle}
						onClick={() => onClickAgree("personal")}
					>
						<Icon>x</Icon>
					</IconButton>
					<p>
                        I hereby understand that the amount will be disbursed to
                        the student&apos; education institute account only
					</p>
					<Button
						variant="contained"
						color="primary"
						onClick={() => onClickAgree("university")}
					>
                        I AGREE
					</Button>
				</Box>
			</Modal>
		</div>
	);
};

export default AccountTypeModal;
