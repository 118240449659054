import React from "react";
import "./app.css";
import AppRouter from "../routes";

function App() 
{
	return <AppRouter />;
}

export default App;
