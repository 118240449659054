import {
	configureStore,
	combineReducers,
	MiddlewareArray,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import reducers from "../../store/reducers";
import middlewares from "../../store/middlewares";

export const store = configureStore({
	reducer: combineReducers({
		...reducers,
	}),
	middleware: new MiddlewareArray().concat(middlewares).concat(thunk),
	devTools: process.env.REACT_APP_ENV === "production" ? false : true,
});

export type RootState = ReturnType<typeof store.getState>
