import React, { useEffect, useState } from "react";
import "./style.scss";
import PropelldLogo from "./PropelldLogo";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bgImage from "../../assets/bg.png";
import { useSearchParams } from "react-router-dom";
import BeneFit1 from "../../assets/benefit_1.svg";
import BeneFit2 from "../../assets/benefit_2.svg";
import BeneFit3 from "../../assets/benefit_3.svg";
import Aksh_Byjus from "../../assets/akash_byjus.svg";
import Allen from "../../assets/allen.webp";
import Upgrad from "../../assets/upgrad.webp";
import Avanse from "../../assets/avanse.webp";
import AdtBirla from "../../assets/adt_birla.webp";
import Jmf from "../../assets/jmf.webp";
import Bfsi from "../../assets/bfsi.webp";
import Toi from "../../assets/toi.webp";
import EduAdv from "../../assets/eduAdv.webp";
import Support from "../../assets/support.svg";
import RightImg from "../../assets/right_side.webp";
import Logo from "../../assets/logo.svg";
import Tick from "../../assets/tick.svg";

type Props = {
    children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
	benefit__item: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "#EAF3FF",
		gap: "1rem",
		padding: "8px 40px 8px 18px",
		fontSize: "11px",
		borderRadius: "7px",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
	},
}));

const Layout = (props: Props) => 
{
	const [searchParams] = useSearchParams();
	const classes = useStyles();
	const isSmartCredit = searchParams.get("smartcredit") === "true";
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => 
	{
		const handleResize = () => 
		{
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => 
		{
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="wrapper">
			<div className="header__logo">
				<img src={Logo} alt="Propelld Logo" width={150} height={40} />
			</div>
			<div className="main">
				<div className="header__title">
					{/* <img src={Support} alt="Support" width={200} height={100} /> */}
					{isSmartCredit ? (
						<h2>PROPELLD SMART CREDIT PROGRAM</h2>
					) : (
						<h2>
                            Support your child&apos;s Education with a Propelld
                            Study Loan today!
						</h2>
					)}
				</div>

				{isSmartCredit && (
					<div className="smart__credit__banner">
						<p style={{ fontWeight: "bold", fontSize: "17px" }}>
                            Fund College Fees at 0% for <br></br> First 60 Days
						</p>
						<ul>
							<li>
								<span>
									<img src={Tick} width={15} alt="tick" />
								</span>
								<p>No Processing Fees</p>
							</li>
							<li>
								<span>
									<img src={Tick} width={15} alt="tick" />
								</span>
								<p>10X Faster Process than Banks</p>
							</li>
							<li>
								<span>
									<img src={Tick} width={15} alt="tick" />
								</span>
								<p>No Collateral Required</p>
							</li>
						</ul>
					</div>
				)}

				<div className="container">
					<div className="form__container">{props.children}</div>

					<div className="image__container">
						<img
							src={RightImg}
							alt="Right Image"
							width={480}
							height={320}
						/>
					</div>
				</div>

				{windowWidth > 700 ? (
					<div className="our__benefits">
						<div className={classes.benefit__item}>
							<img
								src={BeneFit1}
								alt="BeneFit1"
								width={20}
								height={30}
							/>
							<p>Financing Education Since 2017</p>
						</div>
						<div className={classes.benefit__item}>
							<img
								src={BeneFit2}
								alt="BeneFit2"
								width={20}
								height={30}
							/>
							<p>Supporting 2 Lakhs+ Students</p>
						</div>
						<div className={classes.benefit__item}>
							<img
								src={BeneFit3}
								alt="BeneFit3"
								width={32}
								height={30}
							/>
							<p>Working With 500+ Institutes</p>
						</div>
					</div>
				) : (
					<div className="our__benefits__mobview">
						<div>
							<img
								src={BeneFit1}
								alt="BeneFit1"
								width={20}
								height={30}
							/>
							<p>Financing Education Since 2017</p>
						</div>
						<div>
							<img
								src={BeneFit2}
								alt="BeneFit2"
								width={20}
								height={30}
							/>
							<p>Supporting 2 Lakhs+ Students</p>
						</div>
						<div>
							<img
								src={BeneFit3}
								alt="BeneFit3"
								width={30}
								height={30}
							/>
							<p>Working With 500+ Institutes</p>
						</div>
					</div>
				)}

				<div className="our_partners">
					<div>
						<p>SOME OF THE INSTITUTES WE WORK WITH</p>
					</div>
					<div className="items">
						<img
							src={Aksh_Byjus}
							alt="Aksh_Byjus"
							height={50}
							width={100}
						/>
						<img src={Allen} alt="Allen" height={50} width={100} />
						<img src={Upgrad} alt="Upgrad" height={50} width={75} />
					</div>
				</div>

				{/* <div className="our_partners">
					<div>
						<p>LENDING PARTNERS</p>
					</div>
					<div className="items">
						<img
							src={Avanse}
							alt="Avanse"
							height={30}
							width={100}
						/>
						<img
							src={AdtBirla}
							alt="AdtBirla"
							height={30}
							width={100}
						/>
						<img src={Jmf} alt="Jmf" height={30} width={100} />
					</div>
				</div> */}

				<div className="about">
					<p>ABOUT PROPELLD</p>
					<span>
                        Student-friendly private study loans for learners
                        pursuing education from partner institutions with
                        customized loan products through a fully digital loan
                        process.
					</span>
				</div>

				<div className="our_partners">
					<div>
						<p>PROPELLD IN NEWS</p>
					</div>
					<div className="items" style={{ gap: "2.6rem" }}>
						<img src={Bfsi} alt="Bfsi" height={30} width={115} />
						<img src={Toi} alt="Toi" height={40} width={50} />
						<img src={EduAdv} alt="EduAdv" height={40} width={95} />
					</div>
				</div>
			</div>
			<p
				style={{
					textAlign: "center",
					fontSize: "10px",
					marginTop: "50px",
				}}
			>
                Copyright &copy; 2023 Propelld All Rights Reserved{" "}
			</p>
		</div>
	);
};

export default Layout;
