import { createAction } from "@reduxjs/toolkit";
import { GenerateUserConsentOTPRequest } from "../../services/customer/models/GenerateUserConsent";
import { GetPincodeDetailsRequest } from "../../services/customer/models/GetPincodeDetailsRequest";
import { ResendUserConsentOTPRequest } from "../../services/customer/models/ResendUserConsentOTP";
import { VerifyUserConsentOTPRequest } from "../../services/customer/models/VerifyUserConsent";
import { actionRequest } from "../helper";
import { UpdateAccountTypeRequest } from "services/customer/models/UpdateAccountType";
import { TriggerPabblyWebhooRequest } from "services/customer/models/TriggerPabblyWebhook";

export const actions = {
	FETCH_PINCODE_DETAILS: "FETCH_PINCODE_DETAILS",
	GENERATE_USER_CONSENT_OTP: "GENERATE_USER_CONSENT_OTP",
	VERIFY_USER_CONSENT_OTP: "VERIFY_USER_CONSENT_OTP",
	RESEND_USER_CONSENT_OTP: "RESEND_USER_CONSENT_OTP",
	UPDATE_ACCOUNT_TYPE: "UPDATE_ACCOUNT_TYPE",
	TRIGGER_PABBLY_WEBHOOK: "TRIGGER_PABBLY_WEBHOOK",
};

export const fetchPincodeDetails = createAction<GetPincodeDetailsRequest>(
	actionRequest(actions.FETCH_PINCODE_DETAILS),
);

export const generateUserConsentOtp =
    createAction<GenerateUserConsentOTPRequest>(
    	actionRequest(actions.GENERATE_USER_CONSENT_OTP),
    );

export const verifyUserConsentOtp = createAction<VerifyUserConsentOTPRequest>(
	actionRequest(actions.VERIFY_USER_CONSENT_OTP),
);

export const resendUserConsentOtp = createAction<ResendUserConsentOTPRequest>(
	actionRequest(actions.RESEND_USER_CONSENT_OTP),
);

export const updateAccountType = createAction<UpdateAccountTypeRequest>(
	actionRequest(actions.UPDATE_ACCOUNT_TYPE),
);

export const triggerPabblyWebhook = createAction<TriggerPabblyWebhooRequest>(
	actionRequest(actions.TRIGGER_PABBLY_WEBHOOK),
);
