import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";

import Page404 from "../pages/Page404";
// import ReferralPage from "pages/ReferralPage";

export const siteMap = {
	HomePage: {
		title: "Home",
		path: "/",
		description: "My home page",
	},
	// ReferralPage: {
	// 	title: "Referral",
	// 	path: "/referral",
	// 	description: "Referral page",
	// },
};

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const MainRouter = () => (
	<Routes>
		{/* <Route path={siteMap.ReferralPage.path} element={<ReferralPage />} /> */}
		<Route path={siteMap.HomePage.path} element={<HomePage />} />
		<Route path="*" element={<Page404 />} />
	</Routes>
);

export default MainRouter;
